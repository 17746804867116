.layout-footer {
    transition: margin-left $transitionDuration;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    color: white;
    //border-top: 1px solid var(--surface-border);
}


.terms {
    margin-left: 300px;
    cursor: pointer;
    margin-bottom: 0;
}

.privacy {
    margin-left: 20px;
    cursor: pointer;
}