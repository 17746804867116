/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";
@import "assets/sass/layout/_variables";
@import "assets/sass/layout/_layout";
@import "assets/sass/overrides/_overrides";

agm-map {
    height: 500px;
}


.gm-ui-hover-effect {
    display: none !important;
}



/* You can add global styles to this file, and also import other style files */
.pointer{
    cursor: pointer;
}
 
.mrgr-10{
    margin-right: 10px;
}
.mrgr-15{
    margin-right: 15px;
}
.mrgr-20{
    margin-right: 20px;
}
.mrgr-25{
    margin-right: 25px;
}
.mrgr-30{
    margin-right: 30px;
}

.mrgl-10{
    margin-left: 10px;
}
.mrgl-15{
    margin-left: 15px;
}
.mrgl-20{
    margin-left: 20px;
}
.mrgl-25{
    margin-left: 25px;
}
.mrgl-30{
    margin-left: 30px;
}
.mrgl-35{
    margin-left: 35px;
}
.mrgl-40{
    margin-left: 40px;
}
.mrgl-45{
    margin-left: 45px;
}
.mrgl-50{
    margin-left: 50px;
}


.mrgt-5{
    margin-top: 5px;
}
.mrgt-10{
    margin-top: 10px;
}
.mrgt-15{
    margin-top: 15px;
}
.mrgt-20{
    margin-top: 20px;
}
.mrgt-25{
    margin-top: 25px;
}
.mrgt-30{
    margin-top: 30px;
}
.mrgt-40{
    margin-top: 40px;
}
.d-block{
    display: block;
}
.d-none{
    display: none;
}
.d-flex{
    display:flex;
}
.d-unset{
    display:unset;
}
.error{
    color:red;
    font-size: 14px;
    font-family: sans-serif;
}
.multiselect_display_block .p-multiselect-label{
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}
.multiselect-input-width .p-multiselect{
    width: 180px !important;
}
.exception-flex-style{
    display: flex;
    flex-wrap: wrap;
}
.exception-flex-style-child {
    flex: 1 0 21%;
    margin: 5px;
    background: #446799;
    border-color:  #446799;
  }
.exception-flex-style-child:hover, .exception-flex-style-child.active{
    background: #292930 !important;
    border-color: #292930 !important;
}
